<template>
  <form action="#" class="form" data-gw-tab="vote" data-gw-tab-grp="form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Choose</div>
      </div>
      <select
        v-model="vote"
        class="tt-uppercase"
        data-select-vote
        translate="no"
      >
        <option value="https://vgw.hopzone.net/site/vote/104541/1">
          Hopzone
        </option>
        <option value="https://l2network.eu/index.php?a=in&u=La2Dream">
          L2Network
        </option>
        <option value="https://top.l2jbrasil.com/index.php?a=in&u=La2Dream">
          L2Jbrasil
        </option>
      </select>
    </div>
    <!-- END  field -->

    <div class="form__btn-box">
      <a :href="vote" target="_blank" class="button" data-btn-vote>
        <span class="button__content">Continue</span>
      </a>
    </div>
  </form>
</template>

<script>
export default {
  name: "Vote",
  data() {
    return {
      vote: "https://vgw.hopzone.net/site/vote/104541/1",
    };
  },
};
</script>

<style scoped></style>
